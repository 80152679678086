import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import Router, { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { capitalizeFirst } from "lib/helpers/string";
import Routes from "lib/routes";
import Button from "components/Common/Button";
import { FormErrorP, FormInput } from "components/Common/Form";
import { ContainerRow } from "components/Common/Grid/Container";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { H2, P } from "components/Common/typography";
import useSignIn, { Status } from "modules/login/methods/useSignIn";
import { FormLogin, StylCard, StylLoginLink, FormGroup } from "../styles";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email cannot be left blank")
    .trim()
    .email("Invalid email address"),
  password: yup.string().required("Password cannot be left blank"),
});

function SignInView() {
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const router = useRouter();

  const { handleSignIn, error, isLoggedIn, loading, status } = useSignIn();

  function redirect() {
    if (router.query.b) return Router.push(router.query.b as string);
    return Router.push(Routes.App.Items);
  }

  if (status === Status.Success) {
    redirect();
  }

  return (
    <StylCard padding="big">
      <H2>Sign in</H2>
      <P light font="primary">
        Provide your details to log into the system
      </P>
      <FormLogin onSubmit={handleSubmit(handleSignIn)}>
        <FormGroup>
          <FormInput
            name="email"
            type="text"
            placeholder="E-mail address"
            ref={register}
          />
          {errors.email && (
            <FormErrorP>{capitalizeFirst(errors.email.message)}</FormErrorP>
          )}
        </FormGroup>

        <FormGroup>
          <FormInput
            name="password"
            type="password"
            ref={register}
            placeholder="Password"
          />
          {errors.password && (
            <FormErrorP>{capitalizeFirst(errors.password.message)}</FormErrorP>
          )}
        </FormGroup>

        {error && typeof error === "string" && (
          <FormErrorP>{capitalizeFirst(error)}</FormErrorP>
        )}
        {loading ? (
          <ContainerRow>
            <LoadingSpinner
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          </ContainerRow>
        ) : (
          <Button
            primary
            fullWidth
            type="submit"
            disabled={loading || isLoggedIn}
          >
            {!isLoggedIn ? "Sign in" : "Signed in"}
          </Button>
        )}
      </FormLogin>
      <P weight="semiBold">
        Do you forget your password?{" "}
        <Link passHref href={Routes.App.GenerateToken}>
          <StylLoginLink>Reset password</StylLoginLink>
        </Link>
      </P>
      <P weight="semiBold">
        Don&#39;t have an account yet?{" "}
        <Link passHref href={Routes.App.SignUp}>
          <StylLoginLink>Sign up</StylLoginLink>
        </Link>
      </P>
    </StylCard>
  );
}

export default SignInView;
