import React from "react";
import { useAuth } from "lib/auth/authContext";

export enum Status {
  Success = "success",
}

function useSignIn() {
  const [status, setStatus] = React.useState<Status>();
  const { error, loading, login, isLoggedIn } = useAuth();

  async function handleSignIn(data: { email: string; password: string }) {
    const res = await login({ email: data.email, password: data.password });
    if (res === "success") setStatus(Status.Success);
  }

  return {
    error,
    loading,
    status,
    isLoggedIn,
    handleSignIn,
  };
}

export default useSignIn;
