import React from "react";
import WithAuth from "lib/auth/authContext/withAuth";
import Routes from "lib/routes";
import LoginLayout from "components/Layout/Login.layout";
import SignInView from "modules/login/views/SignInView";
import backgroundImage from "assets/images/login-bg-1.jpg";

function SignInPage() {
  return (
    <LoginLayout bgImage={backgroundImage}>
      <SignInView />
    </LoginLayout>
  );
}

export default WithAuth(SignInPage, {
  forLoggedIn: false,
  fallback: Routes.App.Items,
});
